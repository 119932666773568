<template>
    <div>
        <div style="margin-bottom: 20px">当前活跃值总额：<span>{{ filter.list.length ? filter.list[0].newGrowRate : 0 }}</span></div>
        <el-table :data="filter.list" v-loading="tableLoading" border>
            <el-table-column prop="sourceType" label="变更事项" width="100">
                <template slot-scope="scope">
                    {{ scope.row.sourceType | getSourceTypeName }}
                </template>
            </el-table-column>
            <el-table-column prop="sourceNo" label="关联订单号" width="180"></el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column prop="changedGrowRate" label="变更金额" width="120"></el-table-column>
            <el-table-column prop="newGrowRate" label="变更后余额" width="120"></el-table-column>
            <el-table-column prop="createTime" label="操作时间" width="160"></el-table-column>
        </el-table>
        <el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
                       :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange"
                       @current-change="currentChange" background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
</template>

<script>
    import { userGrowRateList } from '@/api/TurnTomySelf';
    export default {
        props: {
            formData: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                tableLoading: false,
                filter: {
                    pageNo: 1,
                    pageSize: 10,
                    total: 0,
                    list: [],
                },
            }
        },
        mounted() {
            this.search();
        },
        methods: {
            search() {
                this.filter.pageNo = 1;
                this.getList();
            },
            pageSizeChange(size) {
                this.filter.pageSize = size;
                this.getList();
            },
            currentChange(page) {
                this.filter.pageNo = page;
                this.getList();
            },
            // 获取列表
            async getList() {
                this.tableLoading = true;
                let { data, alertMsg } = await userGrowRateList({
                    userId: this.formData.id,
                    pageNo: this.filter.pageNo,
                    pageSize: this.filter.pageSize,
                });
                if (data) {
                    this.filter.list = data.records || [];
                    this.filter.total = data.total;
                } else {
                    this.$message({type: 'error', message: alertMsg || '未知错误！'});
                }
                setTimeout(() => {
                    this.tableLoading = false;
                }, 300);
            },
        }
    }
</script>