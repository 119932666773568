<template>
	<div class="main-content">
		<el-form inline size="small" label-width="100px">
			<el-form-item label="用户ID：">
				<el-input v-model="userId" placeholder="用户ID" onkeyup="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"></el-input>
			</el-form-item>
			<el-form-item label="手机号：">
				<el-input v-model="searchKey" placeholder="手机号"></el-input>
			</el-form-item>
			<el-form-item label="用户昵称：">
				<el-input v-model="searchKey2" placeholder="用户昵称"></el-input>
			</el-form-item>
			<el-form-item label="注册时间：">
				<el-date-picker v-model="vipTime" type="datetimerange"
						:picker-options="pickerOptions" range-separator="～"
						start-placeholder="开始日期" end-placeholder="结束日期"
						align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" style="width:90px;margin-left: 20px;" @click="handleFilter">搜索</el-button>
				<button-permissions :datas="'batchCoupon'">
					<el-button type="primary" style="width:150px;margin-left: 20px;" @click="handleBatchCoupon">批量发放优惠券</el-button>
				</button-permissions>
				<button-permissions :datas="'vipExoprt'">
					<el-button type="primary" style="width:90px;margin-left: 20px;" @click="handleExoprt" v-loading="exportLoading">导出</el-button>
				</button-permissions>
			</el-form-item>
		</el-form>
		<el-table :data="vipList" ref="tab" :row-key="getRowKeys" @selection-change="handleSelectionChange" style="width: 100%;margin-bottom: 20px;" border
			v-loading="listLoading">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="id" label="用户ID" width="120px"></el-table-column>
			<el-table-column label="推荐人" width="220px">
				<template slot-scope="scope">
					<div style="display: flex;align-items: center;">
						<div class="iv-heaer">
							<img v-if="scope.row.inviterUrl" :src="scope.row.inviterUrl" alt />
						</div>
						<div style="margin-left: 5px;">
							<div>{{scope.row.inviterName}}</div>
							<div>{{scope.row.inviterPhone}}</div>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="会员信息" width="220px">
				<template slot-scope="scope">
					<div style="display: flex;align-items: center;">
						<div class="iv-heaer">
							<img v-if="scope.row.headUrl" :src="scope.row.headUrl" alt />
							<img v-else :src="defaultHeader" alt />
						</div>
						<div style="margin-left: 5px;">
							<div>{{scope.row.nickName}}</div>
							<div>{{scope.row.phoneNumber}}</div>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column width="120px" label="会员类型">
				<template slot-scope="scope">
					{{ scope.row.pusherLevel | getUserLevelName }}
				</template>
			</el-table-column>
			<el-table-column prop="payableAmount" label="消费金额" width="150px"></el-table-column>
			<el-table-column prop="num" label="订单数量" width="120px"></el-table-column>
			<el-table-column prop="createTime" label="注册时间" width="180px"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<div style="display:flex;">
						<button-permissions :datas="'vipLook'">
							<el-button type="text" style="margin-left: 15px;" class="pointers" @click="showVipDetail(scope.row)">查看</el-button>
						</button-permissions>
						<button-permissions :datas="'vipCreateOrder'">
							<el-button type="text" style="margin-left: 15px;" class="pointers" @click="vipCreateOrder(scope.row)">创建订单</el-button>
						</button-permissions>
						<button-permissions v-if="!scope.row.pusherLevel" :datas="'vipChangePush'">
							<el-button type="text" style="margin-left: 15px;" class="pointers" @click="editLockpush(scope.row)">修改锁粉推手</el-button>
						</button-permissions>
						<button-permissions :datas="'couponGive'">
							<el-button type="text" style="margin-left: 15px;" class="pointers" @click="couponGive(scope.row)">发放优惠券</el-button>
						</button-permissions>
						<button-permissions v-if="!scope.row.pusherLevel" :datas="'authPusher'">
							<el-button type="text" style="margin-left: 15px;" class="pointers" @click="authorizeAgent(scope)">授权推手</el-button>
						</button-permissions>
						<el-dropdown trigger="click" style="margin-left: 10px">
							<el-button type="text">更多</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<div @click="handleLivenessAdd(scope)">调整活跃值</div>
								</el-dropdown-item>
								<el-dropdown-item>
									<div @click="handleLivenessDetail(scope)">活跃值明细</div>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total" style="padding:20px; text-align: right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>

		<!-- 修改锁粉推手弹框 -->
		<el-dialog title="修改锁粉推手" :visible.sync="editeLockpushVisi" width="600px">
			<el-form :model="lockpushForm" ref="lockpushForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="修改锁粉推手:">
					<el-input v-model="lockpushForm.lockpushName" placeholder="推手手机号" clearable style="width:250px" @clear='clearlockInfo'></el-input>
					<el-button type="primary" style="margin-left:30px;width:90px;" @click="lockpushSearch">查询</el-button>
					<div v-if='lockpushForm.firstFromId >0' style="border: 1px solid #67C23A;height: 60px;width:250px;margin-top: 10px;display: flex;align-items: center;position: relative;">
						<img :src="lockpushInfo.headUrl?lockpushInfo.headUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
						<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
							<div style="font-size: 14px;color: #303133;line-height: 25px;">{{lockpushInfo.nickName}}</div>
							<div style="font-size: 14px;color: #303133;line-height: 25px;">{{lockpushInfo.pusherLevel | getUserLevelName}}</div>
						</div>
						<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
							<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
						</div>
					</div>
					<div v-if='IsSearch' style="color:#E51C23;">查询不到相关推手信息，请确认输入手机号无误</div>
				</el-form-item>

				<div style="text-align: center; margin-top: 30px;">
					<el-button style="width: 120px;" @click="editeLockpushVisi=false">关闭</el-button>
					<el-button type="primary" style="width: 120px;margin-left:25px;" @click="Savelockpush">确认修改</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 发送优惠券 -->
		<el-dialog title="发送优惠券" :visible.sync="giveCouponDialog" width="600px">
			<el-form label-width="120px" class="demo-ruleForm">
				<el-form-item label="选择优惠券 :" label-width="150px">
					<!-- :hide-on-click="false" -->
					<el-dropdown @command="handleCommand" style="width: 250px;height: 36px;border: 1px solid #d4d5d6;border-radius: 5px;">
						<div class="el-dropdown-link" style="display: flex;align-items: center;">
							<div style="width: 220px;height: 36px;line-height: 36px;padding-left: 10px;">{{couponName?couponName:'选择优惠券'}}</div><i class="el-icon-arrow-down el-icon--right"></i>
						</div>
						<el-dropdown-menu slot="dropdown" style="width: 250px;height: 300px;overflow: auto;">
							<el-dropdown-item v-for="(item,index) in couponList" :key="index" :command="index" >{{item.couponName}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</el-form-item>

				<el-form-item label="发放数量 :" label-width="150px" >
					<el-input v-model="couponCount" placeholder="发放数量" clearable style="width:250px" maxlength="2"></el-input>
				</el-form-item>
				<div style="margin-left: 150px;font-size: 12px;color: #007AFF;">提示:优惠券发放数量最小数量1，最大发送数量10</div>
				<div style="text-align: center;margin-top: 30px;">
					<el-button style="width: 120px;" @click="giveCouponDialog=false">关闭</el-button>
					<el-button type="primary" style="width: 120px;margin-left:25px;" @click="sureGiveCoupon">发放</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 批量发送优惠券 -->
		<el-dialog title="发送优惠券" :visible.sync="batchCouponDialog" width="600px">
			<el-form label-width="120px" class="demo-ruleForm">
				<el-form-item label="选择优惠券 :" label-width="150px">
					<!-- :hide-on-click="false" -->
					<el-dropdown @command="handleCommand" style="width: 250px;height: 36px;border: 1px solid #d4d5d6;border-radius: 5px;">
						<div class="el-dropdown-link" style="display: flex;align-items: center;">
							<div style="width: 220px;height: 36px;line-height: 36px;padding-left: 10px;">{{couponName?couponName:'选择优惠券'}}</div><i class="el-icon-arrow-down el-icon--right"></i>
						</div>
						<el-dropdown-menu slot="dropdown" style="width: 250px;height: 300px;overflow: auto;">
							<el-dropdown-item v-for="(item,index) in couponList" :key="index" :command="index" >{{item.couponName}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</el-form-item>

				<!-- <el-form-item label="发放数量 :" label-width="150px" >
					<el-input v-model="couponCount" placeholder="发放数量" clearable style="width:250px" maxlength="2"></el-input>
				</el-form-item> -->
				<!-- <div style="margin-left: 150px;font-size: 12px;color: #007AFF;">提示:优惠券发放数量最小数量1，最大发送数量10</div> -->
				<div style="text-align: center;margin-top: 30px;">
					<el-button style="width: 120px;" @click="batchCouponDialog=false">关闭</el-button>
					<!-- <el-button type="primary" style="width: 120px;margin-left:25px;" v-if="couponLoading" disabled v-loading="couponLoading">发放</el-button> -->
					<el-button type="primary" style="width: 120px;margin-left:25px;" @click="tatchUserCoupon" :disabled="couponLoading" v-loading="couponLoading">发放</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 授权推手-->
		<el-dialog title="授权推手" :visible.sync="authorizeFlag" width="520px">
			<el-form :model="authorizeData" ref="editUpperForm" size="small" label-width="100px">
				<el-form-item label="用户姓名：">
					<span>{{ authorizeData.name }}</span>
				</el-form-item>
				<el-form-item v-if="authorizeData.phone" label="手机号码：">
					<span>{{ authorizeData.phone }}</span>
				</el-form-item>
				<el-form-item label="推手等级：">
					<el-select v-model="authorizeData.pushLevel" placeholder="请选择授权的推手等级">
						<el-option v-for="item in LEVELLIST" :key="item.pusherLevel" :label="item.pusherLabel" :value="item.pusherLevel"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注说明：" required>
					<el-input type="textarea" v-model="authorizeData.remark" placeholder="输入备注说明" maxlength="20"></el-input>
				</el-form-item>
				<div style="text-align:center;">
					<el-button size="small"  @click="authorizeFlag=false"> 取消 </el-button>
					<el-button size="small" :disabled="!authorizeData.remark" type="primary" @click="subAuthorizeAgent">
						确认
					</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 增加活跃值 -->
		<el-dialog :title="addLivenessTitle" :visible.sync="addLivenessFlag" width="600px" @closed="clearDataFunc">
			<AddLiveness v-if="addLivenessData" :formData="addLivenessData" @closeDialog="closeDialog"></AddLiveness>
		</el-dialog>

		<!-- 活跃值明细 -->
		<el-dialog :title="livenessDetailTitle" :visible.sync="livenessDetailFlag" width="70%" @closed="clearDataFunc">
			<LivenessDetail v-if="livenessDetailData" :formData="livenessDetailData" @closeDialog="closeDialog"></LivenessDetail>
		</el-dialog>
	</div>
</template>

<script>
	import {
		findByCouponName,
		grantUserCoupon,
		memberdistibutorAuth,
		tatchUserCoupon,
		UserInfoExcel,
		vipFindAll,
	} from '../../api/wyUsedInterface.js'
	import config from '@/config/index';
	import utils from '@/utils/util'
	import {changeFirstFromMember, findRecommendMember,} from '@/api/TurnTomySelf'
	import buttonPermissions from '@/components/buttonPermissions';
	import AddLiveness from './liveness/addLiveness';
	import LivenessDetail from './liveness/livenessDetail';

	export default {
		name: 'vipList',
		components: {
			buttonPermissions,
			AddLiveness,
			LivenessDetail,
		},
		data() {
			return {
				vipList: [],
				listLoading: false,
				userId: '', //用户id
				searchKey: '',
				searchKey2: '',
				vipTime: '',
				pageNo: 1,
				pageSize: 20,
				total: 0,
				defaultHeader: config.DEFAULT_HEADER,
				editeLockpushVisi: false,
				lockpushForm: {
					lockpushName: '', //查询的手机号
					firstFromId: 0, //查询到的推手Id
				},
				lockpushInfo: {
					headUrl: '',
					nickName: '',
					pusherLevel: 0,
				},
				IsSearch: false,
				currentRow: null,
				giveCouponDialog: false,
				couponName: null,
				couponCount: '',
				couponList:[],
				couponIndex:0,
				multipleSelection: [],
				pickerOptions: {        //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick (picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick (picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick (picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				startTime:'',
				endTime:'',
				exportLoading:false,
				batchCouponDialog:false,
				couponLoading:false,
				authorizeData: {
					id: null,
					name: '',
					phone: '',
					pushLevel: null,
					remark: '',
				},
				authorizeFlag: false,

				//活跃值调整
				addLivenessTitle: '',
				addLivenessFlag: false,
				addLivenessData: null,

				//增加活跃值
				livenessDetailTitle: '',
				livenessDetailFlag: false,
				livenessDetailData: null,
			}
		},
		created() {
			this.initData();
			//this.findByCouponName(1);
		},
		methods: {
			async initData() {
				if(this.vipTime){
					this.startTime = this.vipTime[0];
					this.endTime = this.vipTime[1];
				}else{
					this.startTime = '';
					this.endTime = '';
				}
				try {
					this.listLoading = true;
					let params = {
						pageNo: this.pageNo,
						pageSize: this.pageSize,
						createTime: this.startTime,
						endTime:this.endTime,
						phoneNumber: this.searchKey,
						nickName: this.searchKey2,
						userId: this.userId
					};
					let { data } = await vipFindAll(params);
					if(data) {
						data.records.map(item => {
							item.payableAmount = item.payableAmount ? item.payableAmount.toFixed(2) : '0.00';
							item.num = item.num ? item.num : 0;
							return item;
						});
						this.vipList = data.records;
						this.total = data.total;
					}
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.listLoading = false;
				}
			},
			async findByCouponName(type){
				try{
					let res = await findByCouponName({});
					if(res.success){
						this.couponList = res.data;
						//type==1是初次调用
						if(type==2){
							if(this.couponList.length){
								this.giveCouponDialog = true;
							}else{
								this.$message({
									showClose: true,
									type: 'warning',
									message: '暂无可发放的优惠券'
								});
							}
						}
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			handleFilter() {
				this.pageNo = 1;
				this.initData();
			},
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			getRowKeys (row) {
				return row.id;
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.initData();
			},
			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.pageNo = val;
				this.initData();
			},
			showVipDetail(row) {
				this.$router.push({
					path: '/Customer/vipDetail',
					query: {
						id: row.id
					}
				})
			},
			vipCreateOrder(row) {
				this.$router.push({
					path: '/Customer/vipCreateOrder',
					query: {
						id: row.id,
						headUrl: row.headUrl,
						nickName: row.nickName
					}
				})
			},
			// 修改锁粉推手
			editLockpush(row) {
				this.currentRow = row;
				this.editeLockpushVisi = true
			},
			// 确认修改
			async Savelockpush() {
				try {
					if (this.lockpushForm.lockpushName != this.oldPhone && this.lockpushForm.lockpushName != '') {
						this.$message({ showClose: true, type: 'error', message: '请点击[查询]确认推手信息' });
						return
					}
					if (this.IsSearch) {
						this.$message({ showClose: true, type: 'error', message: '请输入正确的推手信息' });
						return
					}
					let result = await changeFirstFromMember({
						id: this.currentRow.id,
						invitendId: this.lockpushForm.firstFromId
					});
					if (result.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '修改成功'
						});
						this.vipList.map(item => {
							if (item.id == this.currentRow.id) {
								item.inviterName = this.lockpushInfo.nickName;
								item.inviterPhone = this.lockpushForm.lockpushName;
								item.inviterUrl = this.lockpushInfo.headUrl;
							}
							return item;
						});
						this.editeLockpushVisi = false
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			// 锁粉推手查询
			async lockpushSearch() {
				try {
					if (!this.lockpushForm.lockpushName) {
						this.$message({ showClose: true, type: 'error', message: '请输入推手信息'});
						return
					}
					let result = await findRecommendMember({
						phone: this.lockpushForm.lockpushName
					});
					if (result.success) {
						if (result.data) {
							this.lockpushInfo.headUrl = result.data.headUrl;
							this.lockpushInfo.nickName = result.data.nickName;
							if (result.data.pusherLevel) {
								this.lockpushInfo.pusherLevel = result.data.pusherLevel;
							}
							this.IsSearch = false;
							this.lockpushForm.firstFromId = result.data.id;
							this.oldPhone = this.lockpushForm.lockpushName
						} else {
							this.IsSearch = true;
						}
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
						this.clearlockInfo();
						this.IsSearch = true;
					}
				} catch (err) {
					console.log(err)
				}
			},
			//清楚查询推手的手机号
			clearlockInfo() {
				console.log('没有触发清楚事件吗');
				this.lockpushInfo = {
					headUrl: '',
					nickName: '',
					pusherLevel: 0,
				};
				this.lockpushForm.firstFromId = 0;
				if (this.IsSearch) {
					this.IsSearch = false
				}
			},
			couponGive(row) {
				this.currentRow = row;
				this.couponName = null;
				this.couponCount="";
				this.findByCouponName(2);
			},
			//显示授权推手备注填写
			authorizeAgent({row, $index}) {
				this.authorizeData = {
					index: $index,
					id: row.id,
					name: row.nickName,
					phone: row.phoneNumber,
					pushLevel: null,
					remark: '',
				};
				this.authorizeFlag = true;
			},
			//确认提交授权
			async subAuthorizeAgent() {
				let params = {
					userId: this.authorizeData.id,
					pushLevel: this.authorizeData.pushLevel,
					remark: this.authorizeData.remark,
					operator: localStorage.getItem("mlmzpageRealName"),
				};
				if(!params.remark) {
					this.$message({type: 'success', message: '请填写备注说明！'});
					return;
				}
				let { code, alertMsg } = await memberdistibutorAuth(params);
				this.authorizeFlag = false;
				if(200 === code) {
					this.$message({type: 'success', message: '授权推手成功！'});
					let item = {...this.vipList[this.authorizeData.index]};
					item.pusherLevel = params.pushLevel;
					this.vipList.splice(this.authorizeData.index, 1, item)
				} else {
					this.$message({type: 'error', message: alertMsg || '未知错误'});
				}
			},
			handleCommand(command) {
				this.couponIndex = +command||0;
				this.couponName = this.couponList[this.couponIndex].couponName;
			},
			sureGiveCoupon(){
				if(!this.couponName){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请选择优惠券'
					});
					return;
				}
				if(!this.couponCount || !Number.parseInt(this.couponCount) || Number.parseInt(this.couponCount)<=0){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请填写优惠券发放数量'
					});
					return;
				}
				if(this.couponCount>10 || this.couponCount <1){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '优惠券发放数量为1~10之间'
					});
					return;
				}
				this.grantUserCoupon();
			},
			async grantUserCoupon(){
				try{
					let data = {
						couponId:this.couponList[this.couponIndex].id,
						num:this.couponCount,
						userId:this.currentRow.id
					};
					let res = await grantUserCoupon(data);
					if(res.success){
						this.$message({
							showClose: true,
							type: 'success',
							message: '优惠券发放成功'
						});
						this.giveCouponDialog = false;
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg ? res.alertMsg : '优惠券发放失败'
						});
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			async handleExoprt() {
				try {
					if(this.vipTime){
						this.startTime = this.vipTime[0];
						this.endTime = this.vipTime[1];
					}else{
						this.startTime = '';
						this.endTime = '';
					}
					this.exportLoading = true;
					let data = {
						// isDownload: true, //下载文件标记
						pageNo: this.pageNo,
						pageSize: this.pageSize,
						createTime: this.startTime,
						endTime:this.endTime,
						phoneNumber: this.searchKey,
						nickName: this.searchKey2,
						userId: this.userId
					};
					let { success, alertMsg } = await UserInfoExcel(data);
					if(success) {
						this.$message({type: 'success', message: '导出成功，请到下载中心下载文件'});
					} else {
						this.$message({type: 'error', message: alertMsg || '未知错误'});
					}
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.exportLoading = false;
				}
			},
			//批量发放优惠券
			async tatchUserCoupon(){
				if(!this.couponName){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请选择优惠券'
					});
					return;
				}
				this.couponLoading = true;
				try{
					let ids = [];
					this.multipleSelection.map(item=>{
						ids.push(item.id);
						return item;
					})
					let data = {
						couponId:this.couponList[this.couponIndex].id,
						userId:ids
					};
					let res = await tatchUserCoupon(data);
					if(res.success){
						this.$message({
							showClose: true,
							type: 'success',
							message: '优惠券发放成功'
						});
						this.multipleSelection = [];
						if (this.$refs['tab']) {
							this.$refs['tab'].clearSelection()
						}
						this.batchCouponDialog = false;
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg ? res.alertMsg : '优惠券发放失败'
						});
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.couponLoading = false;
				}
			},
			handleBatchCoupon(){
				this.$router.push({
					path: '/Customer/vipBatchCoupon',
					query: {}
				})
				// if(!this.multipleSelection.length){
				// 	this.$message({
				// 		showClose: true,
				// 		type: 'error',
				// 		message: '请选择要发放优惠券的会员'
				// 	});
				// 	return;
				// }

				// if(this.couponList.length){
				// 	this.couponName = null;
				// 	this.batchCouponDialog = true;
				// }else{
				// 	this.$message({
				// 		showClose: true,
				// 		type: 'warning',
				// 		message: '暂无可发放的优惠券'
				// 	});
				// }
			},
			// 显示调整活跃值
			handleLivenessAdd({row}) {
				this.addLivenessTitle = '调整活跃值-' + row.nickName;
				this.addLivenessData = {...row};
				this.addLivenessFlag = true;
			},
			// 显示活跃值明细
			handleLivenessDetail({row}) {
				this.livenessDetailTitle = '活跃值明细-' + row.nickName;
				this.livenessDetailData = {...row};
				this.livenessDetailFlag = true;
			},
			//关闭弹出层
			closeDialog(flag) {
				this.addLivenessFlag = false;
				this.livenessDetailFlag = false;
				if (flag) {
					this.initData();
				}
			},
			//清除选择内容
			clearDataFunc() {
				this.addLivenessData = null;
				this.livenessDetailData = null;
			},
		}
	}
</script>

<style scoped lang="less">
	.main-content {
		margin-top: 15px;
		background: #fff;
		padding: 15px 10px;
	}
	.iv-heaer>img {
		width: 50px;
		height: 50px;
		border-radius: 100%;
	}
</style>
