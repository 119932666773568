<template>
    <el-form ref="addLivenessForm" :model="ruleForm"  size="small" label-width="100px">
        <el-form-item label="当前活跃值：">{{ formData.growRate }}</el-form-item>
        <el-form-item label="增减：">
            <el-radio-group v-model="ruleForm.type">
                <el-radio :label="1">增加</el-radio>
                <el-radio :label="2">减少</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="变动数额：" required>
            <el-input-number v-model="ruleForm.number" :controls="false" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="变动备注：" required>
            <el-input v-model="ruleForm.remark" type="textarea" :rows="4" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label=" ">
           <p style="color: #D9001B; font-size: 14px">增加或减少活跃值可能会涉及到用户等级的升降，请谨慎操作</p>
        </el-form-item>
        <div style="text-align:right;">
            <el-button @click="cancelSubmit">取消</el-button>
            <el-button type="primary" @click="saveSubmit">确认</el-button>
        </div>
    </el-form>
</template>

<script>
    import {updGrowRate} from '@/api/TurnTomySelf';

    export default {
        props: {
            formData: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                tableLoading: false,
                ruleForm: {
                    type: 1,
                    number: 0,
                    remark: '',
                },
                isConfirmed: false,
            }
        },
        methods: {
            //取消
            cancelSubmit() {
                this.$refs["addLivenessForm"].clearValidate();
                this.$emit('closeDialog', false);
            },
            // 确认提交
            saveSubmit() {
                this.$refs['addLivenessForm'].validate(valid => {
                    if (valid) {
                       this.saveUpdGrowRate();
                    }
                });
            },
            //确认提交
            checkSubmit(msg) {
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.isConfirmed = true;
                    this.saveUpdGrowRate();
                }).catch(() => {
                    this.isConfirmed = false;
                });
            },
            async saveUpdGrowRate() {
                let params = {
                    userId: this.formData.id,
                    growRate: this.ruleForm.type === 1 ? this.ruleForm.number : this.ruleForm.number*-1,
                    remark: this.ruleForm.remark,
                    isConfirmed: this.isConfirmed,
                };
                this.tableLoading = true;
                let { code, success, alertMsg } = await updGrowRate(params);
                if(success) {
                    this.$message({type: 'success', message: '操作成功！'});
                    this.$emit('closeDialog', true);
                } else {
                    if(code === 10002 || code === 10003) {
                        this.checkSubmit(alertMsg);
                    } else {
                        this.$message({type: 'error', message: alertMsg || '未知错误！'});
                    }
                }
                setTimeout(() => {
                    this.tableLoading = false;
                }, 300);
            },
        }
    }
</script>